//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class EmpleadoServices {
     Consultar( datos ) {
        return http.get("empleado/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

    ConsultarBuscar( datos ) {
        return http.get("empleado/consultar/buscar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

    ConsultarTipoEmpleado( datos ) {
        return http.get("empleado/tipoEmpleado/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 
    ConsultarCargoEmpleado( datos ) {
        return http.get("empleado/cargoEmpleado/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

    Actualizar(
        IdUsuario,
        Data , 
    ) {   
        const datos = {
            IdUsuario ,
            Data 
          }
          console.log(datos) 
        return http.post("/empleado/actualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
 
}


export default new EmpleadoServices();